<script></script>

<div
  class="loader ease-linear h-full w-full rounded-full border-4 border-t-4 border-gray"
/>

<style>
  .loader {
    border-top-color: #3498db;
    animation: spinner 1.5s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
