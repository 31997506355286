<script>
</script>

<header class="bg-black p-2">
  <img
    src="https://shinyaigeek.dev/assets/static/icon_transparent_header.png"
    alt="Shinyaigeek's icon"
    width="36px"
    height="36px"
    class="inline"
  />
  <h1 class="text-white text-3xl inline">
    <a href="https://shinyaigeek.dev/">shinyaigeek.dev</a>
  </h1>
</header>

<style>
</style>
